<template>
  <mobile-screen
    :header="true"
    screen-class="edit-form-screen add-application icon-app1 edit-connector"
  >
    <template v-slot:header>
      <top-header-menu-wrapper menu-class="icon-hea1">
        <template v-slot:left>
          <router-link
            :to="{
              name: this.subdomain ? 'r_connectors-company' : 'r_connectors'
            }"
          >
            <icon icon="#cx-hea1-arrow-left" />
          </router-link>
        </template>
        <div class="component-title">
          {{
            displayLabelName("connectors", "edit-connector", "edit-connector")
          }}
        </div>
        <template v-if="!isOutlookAddInConnector" v-slot:right>
          <button
            v-if="can('CONNECTORS', 'update')"
            @click="initSubmit"
            :disabled="syncing"
          >
            <icon icon="#cx-hea1-save" />
          </button>
        </template>
      </top-header-menu-wrapper>
    </template>
    <VeeForm
      @submit="submitForm"
      :key="formKey"
      ref="editConnectorForm"
      v-slot="{ errors }"
      class="form tiny-input no-borders"
      novalidate
      v-if="selectedConnector && selectedConnector.length"
    >
      <ul class="clebex-item-section" v-if="fields">
        <li
          v-if="
            Object.keys(selectedConnector[0]).includes('is_active') &&
              !isOutlookAddInConnector
          "
          class="clebex-item-section-item full-right-underline"
        >
          <div class="clebex-item-content-wrapper">
            <dl class="clebex-item-dl justify-start">
              <dt class="clebex-item-dt">
                <div class="checkbox slide">
                  <input
                    type="checkbox"
                    id="connector-active"
                    name="is_active"
                    @change="handleChange(selectedConnector[0].is_active)"
                    :checked="selectedConnector[0].is_active"
                    :value="Number(selectedConnector[0].is_active)"
                    :disabled="!can('CONNECTORS', 'update') || syncing"
                  />
                  <label for="connector-active"></label>
                </div>
              </dt>
              <dd class="clebex-item-dd justify-start">
                <div class="checkbox slide">
                  {{
                    displayLabelName(
                      "connectors",
                      "edit-connector",
                      "is-active"
                    )
                  }}
                </div>
              </dd>
            </dl>
          </div>
        </li>
      </ul>
      <ul class="clebex-item-section" v-if="fields">
        <li
          v-for="field in fields"
          :key="field.id"
          class="clebex-item-section-item"
        >
          <div
            v-if="
              field.type && field.type.type && field.type.type === 'checkbox'
            "
            class="clebex-item-content-wrapper"
          >
            <dl class="clebex-item-dl justify-start">
              <dt class="clebex-item-dt">
                <div class="checkbox slide">
                  <input
                    :id="field.field"
                    :name="`checkbox${field.field}`"
                    :type="field.type.type"
                    :disabled="
                      submitting ||
                        !field.is_editable ||
                        !can('CONNECTORS', 'update') ||
                        syncing
                    "
                    :value="parseCheckboxValue(field.content)"
                    :checked="parseCheckboxValue(field.content)"
                    @change="
                      setFieldValue(
                        field.field,
                        !parseCheckboxValue(field.content)
                      )
                    "
                  />
                  <label :for="field.field"></label>
                  <Field
                    :name="field.field"
                    as="input"
                    :rules="field.is_required ? 'required' : ''"
                    type="hidden"
                    :value="Number(parseCheckboxValue(field.content))"
                    :disabled="!can('CONNECTORS', 'update') || syncing"
                  />
                </div>
              </dt>
              <dd class="clebex-item-dd clebex-item-dd-left justify-start">
                {{ field.name }}
              </dd>
            </dl>
          </div>
          <div
            v-else-if="
              field.type && field.type.type && field.type.type === 'button'
            "
            class="clebex-item-content-wrapper"
          >
            <div class="clebex-section-input">
              <label class="clebex-section-input-label" for="firstNameId"
                >{{ field.name }}
              </label>
            </div>
            <button
              @click.prevent="downloadFile(field.content, 'Manifest.xml')"
              class="follow-up-icons"
            >
              <span class="follow-up-icon-item">
                <icon icon="#cx-men1-download-sample"></icon>
              </span>
            </button>
          </div>
          <div v-else class="clebex-item-content-wrapper">
            <div class="clebex-section-input">
              <label class="clebex-section-input-label" for="firstNameId"
                >{{ field.name }}
              </label>
              <Field
                v-if="field.type.type === 'textarea'"
                :name="field.field"
                :as="field.type.type"
                :rules="field.is_required ? 'required' : ''"
                :disabled="
                  submitting ||
                    !field.is_editable ||
                    !can('CONNECTORS', 'update') ||
                    syncing
                "
                :value="field.content"
                >{{ field.content }}</Field
              >
              <Field
                v-else
                :name="field.field"
                as="input"
                :type="field.type.type"
                :rules="field.is_required ? 'required' : ''"
                :disabled="
                  submitting ||
                    !field.is_editable ||
                    !can('CONNECTORS', 'update') ||
                    syncing
                "
                :value="field.content"
              />
            </div>
            <button
              v-if="!field.is_editable"
              @click="copyFieldValue(field.content)"
              class="follow-up-icons"
            >
              <span class="follow-up-icon-item">
                <icon icon="#cx-app1-copy"></icon>
              </span>
            </button>
          </div>
          <span class="error-message" v-if="errors[field.field]">
            {{ errors[field.field] }}
          </span>
        </li>
      </ul>

      <button type="submit" style="display: none;" ref="submitFormBtn">
        Submit form
      </button>
    </VeeForm>
    <template v-slot:footer>
      <section class="plans-footer-menu connectors icon-foo1">
        <nav class="menu" v-if="hasExportButton">
          <ul class="menu-items">
            <li class="menu-item calendar">
              <button
                @click="
                  prepareDownload(
                    getUrl(),
                    'ClebexReports.csv',
                    selectedConnector[0].id !== 7 ? true : false
                  )
                "
              >
                <icon icon="#cx-men1-download-sample" />
                {{ displayLabelName("connectors", "edit-connector", "export") }}
              </button>
            </li>
          </ul>
        </nav>
      </section>
    </template>
    <screen-modal
      class="confirm-modal"
      type="success"
      :confirm-action="deleteLinkedUser"
      :confirm-button-label="
        displayLabelName(
          'connectors',
          'edit-connector',
          'delete-confirm-action'
        )
      "
      :cancelButtonLabel="
        displayLabelName('connectors', 'edit-connector', 'delete-cancel-action')
      "
      :show="showDeleteModal"
      @close="showDeleteModal = false"
    >
      <h3 class="modal-title">
        {{
          displayLabelName("connectors", "edit-connector", "delete-linked-user")
        }}
      </h3>
      <p class="text">
        {{
          displayLabelName(
            "connectors",
            "edit-connector",
            "delete-linked-user-text"
          )
        }}
        <br />
        {{
          displayLabelName(
            "connectors",
            "edit-connector",
            "delete-linked-user-question"
          )
        }}
      </p>
    </screen-modal>
    <div class="status-dialog" v-if="declarationsModal">
      <div class="dialog-wrapper">
        <div class="dialog-container">
          <!-- Status info -->
          <br />

          <div
            class="dialog-body"
            style="flex-direction: column; padding-top: 0px; width: 80%;"
          >
            <div class="dialog-body-row" style="flex-direction: column;">
              <label> {{ displayLabelName("export.options.options") }}</label
              ><br />
              <button
                class="export-button"
                @click="
                  downloadFile(
                    getUrl(),
                    'ClebexReports.csv',
                    true,
                    'last3months'
                  )
                "
              >
                {{ displayLabelName("export.options.last-three-months") }}
              </button>
              <button
                class="export-button"
                @click="
                  downloadFile(
                    getUrl(),
                    'ClebexReports.csv',
                    true,
                    'currentYear'
                  )
                "
              >
                {{ displayLabelName("export.options.current-year") }}
              </button>
              <button
                class="export-button"
                @click="
                  downloadFile(getUrl(), 'ClebexReports.csv', true, 'lastYear')
                "
              >
                {{ displayLabelName("export.options.last-year") }}
              </button>
            </div>
          </div>
          <button
            style="border-top: 1px solid rgb(184, 184, 184); width: 100%; padding-top: 10px; padding-bottom: 3px;"
            @click="declarationsModal = false"
          >
            {{ displayLabelName("global.buttons.cancel") }}
          </button>
        </div>
      </div>
    </div>
  </mobile-screen>
  <router-view />
</template>

<script>
import httpServiceAuth, { getSubdomain } from "@/services/http-service";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import MobileScreen from "@/layouts/MobileScreen";
import { apiEndpoints } from "@/services/constants";
import { mapActions, mapState } from "vuex";
import { errorHandler } from "@/services/error-handler";
import globalMixin from "@/services/mixins/global/global";

export default {
  name: "EditConnector",
  data() {
    return {
      submitting: false,
      subdomain: null,
      formKey: 0,
      syncing: false,
      showDeleteModal: false,
      declarationsModal: false
    };
  },
  created() {
    this.subdomain = getSubdomain();
  },
  mounted() {
    if (!this.selectedConnector) {
      this.$router.push({
        name: this.subdomain ? "r_connector-types-company" : "r_connector-types"
      });
    }
    this.getLinkedUsers();
  },
  watch: {
    selectedConnector() {
      if (this.selectedConnector) {
        this.selectedConnector.length &&
          this.$refs.editConnectorForm.setValues({
            ...this.selectedConnector[0]
          });
      } else {
        this.$refs.editConnectorForm.setValues({
          name: ""
        });
      }
    }
  },
  computed: {
    ...mapState("connector", [
      "selectedConnectorType",
      "selectedConnector",
      "connectors",
      "linkedUsers"
    ]),
    apiEp() {
      return apiEndpoints;
    },
    fields() {
      const subdomain = getSubdomain();
      if (this.selectedConnector && this.selectedConnector.length) {
        if (subdomain) {
          return this.selectedConnector[0].fields.filter(
            field => field.is_visible
          );
        } else {
          return this.selectedConnector[0].fields;
        }
      }
      return null;
    },
    linkedUser() {
      if (this.linkedUsers && this.linkedUsers.length) {
        return this.linkedUsers[0];
      }
      return null;
    },
    submitDisabled() {
      if (this.fields && this.fields.length) {
        const editableLength = this.fields.filter(field => !field.is_editable);
        return editableLength.length === this.fields.length;
      } else {
        return null;
      }
    },
    hasExportButton() {
      if (this.selectedConnector && this.selectedConnector.length) {
        return !!(
          this.selectedConnector[0].fields &&
          this.selectedConnector[0].fields.filter(
            item =>
              item.field === "exportBookingUrl" ||
              item.field === "exportServiceUrl"
          ).length
        );
      }
      return null;
    },
    isOutlookAddInConnector() {
      if (
        this.fields &&
        this.fields.length &&
        this.fields[0].field &&
        this.fields[0].field === "outlook_addin_manifest"
      ) {
        return true;
      }
      return false;
    }
  },
  methods: {
    ...mapActions("connector", [
      "getConnectors",
      "setSelectedConnector",
      "getLinkedUsers"
    ]),
    getUrl() {
      var url = "";

      if (this.selectedConnector && this.selectedConnector.length) {
        if (this.selectedConnector[0] && this.selectedConnector[0].fields) {
          var urlB = this.selectedConnector[0].fields.find(
            item => item.field === "exportBookingUrl"
          );
          if (urlB) {
            url = apiEndpoints.company.exportDeclarations;
          }

          var urlS = this.selectedConnector[0].fields.find(
            item => item.field === "exportServiceUrl"
          );
          if (urlS) {
            url = apiEndpoints.company.exportServices;
          }
        }
      }

      return url;
    },
    submitForm(values, { resetForm }) {
      if (values) {
        const data = {
          ...this.selectedConnector[0]
        };

        data.fields = this.fields.map(field => {
          return {
            ...field,
            content: values[field.field]
          };
        });

        if (Object.keys(this.selectedConnector[0]).includes("is_active")) {
          data.is_active = Number(this.selectedConnector[0].is_active);
        }

        this.submitting = true;
        this.submitMasterData(data, resetForm);
      }
    },
    submitUserForm(values) {
      if (values) {
        this.submitting = true;
        this.submitLinkedUserData(values);
      }
    },
    submitMasterData(values, resetForm) {
      this.$store.commit("loader/setScreenLoading", true, { root: true });

      let url = `${apiEndpoints.master.connectors}/${this.selectedConnector[0].id}`;
      const subdomain = getSubdomain();
      if (subdomain) {
        url = `${apiEndpoints.company.connectors}/${this.selectedConnector[0].id}`;
      }
      httpServiceAuth
        .put(url, values)
        .then(() => {
          resetForm();
          this.getConnectors(this.selectedConnectorType[0].id);
          this.setSelectedConnector([
            {
              ...values
            }
          ]);
          if (this.formKey === 10) {
            this.formKey = 0;
          } else {
            this.formKey++;
          }
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response, this.findElement());
          }
        })
        .finally(() => {
          this.submitting = false;
          this.$store.commit("loader/setScreenLoading", false, { root: true });
          this.syncing = false;
        });
    },
    submitLinkedUserData(values) {
      this.$store.commit("loader/setScreenLoading", true, { root: true });

      httpServiceAuth
        .post(`${apiEndpoints.company.linkedUsers}/all`, values)
        .catch(error => {
          if (error.response) {
            errorHandler(error.response, this.findElement());
          }
        })
        .finally(() => {
          this.getLinkedUsers();
          this.$store.commit("loader/setScreenLoading", false, { root: true });
          this.submitting = false;
        });
    },
    deleteLinkedUser() {
      this.submitting = true;
      this.$store.commit("loader/setScreenLoading", true, { root: true });

      httpServiceAuth
        .delete(`${apiEndpoints.company.linkedUsers}/${this.linkedUser.id}`)
        .catch(error => {
          if (error.response) {
            errorHandler(error.response, this.findElement());
          }
        })
        .finally(() => {
          this.getLinkedUsers();
          this.$store.commit("loader/setScreenLoading", false, { root: true });
          this.submitting = false;
        });
    },
    copyFieldValue(value) {
      const el = document.createElement("textarea");
      el.value = value;
      el.setAttribute("readonly", "");
      el.style.position = "absolute";
      el.style.left = "-9999px";
      document.body.appendChild(el);
      const selected =
        document.getSelection().rangeCount > 0
          ? document.getSelection().getRangeAt(0)
          : false;
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);
      if (selected) {
        document.getSelection().removeAllRanges();
        document.getSelection().addRange(selected);
      }
    },
    setFieldValue(name, value) {
      const field = this.selectedConnector[0].fields.filter(
        item => item.field === name
      );
      if (field && field.length) {
        field[0].content = Number(value);
        this.$refs.editConnectorForm.setFieldValue(name, Number(value));
      }
    },
    parseCheckboxValue(val) {
      if (typeof val === "string") return Boolean(Number(val));
      return Boolean(val);
    },
    initSubmit() {
      const { submitFormBtn } = this.$refs;
      if (submitFormBtn) submitFormBtn.click();
    },
    initUserSubmit() {
      const { submitUserFormBtn } = this.$refs;
      if (submitUserFormBtn) submitUserFormBtn.click();
    },
    handleChange(value) {
      if (this.selectedConnector && this.selectedConnector.length) {
        this.selectedConnector[0].is_active = !value;
      }
    },
    prepareDownload(url, name, loading = false) {
      if (url.includes("declarations")) {
        this.declarationsModal = true;
      } else {
        this.downloadFile(url, name, loading);
      }
    },
    downloadFile(url, name, loading = false, mode = null) {
      if (loading) {
        this.$store.commit("loader/setScreenLoading", true, { root: true });
      }

      if (url.includes("declarations")) {
        url = url + "?period=" + mode;
      }

      return httpServiceAuth
        .get(url, {
          exposedHeaders: ["Content-Disposition"]
        })
        .then(response => {
          let blob = new Blob(["\ufeff" + response.data], {
            type: response.headers["content-type"]
          });
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          const contentDispositionHeader =
            response.headers["content-disposition"];
          let fileName = name;
          if (contentDispositionHeader) {
            const headerFileName = this.extractHeaderFileName(
              contentDispositionHeader
            );
            fileName = headerFileName ? headerFileName : fileName;
          }
          link.download = fileName;
          link.click();
          link.remove();
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          this.$store.commit("loader/setScreenLoading", false, { root: true });
          this.declarationsModal = false;
        });
    }
  },
  components: {
    MobileScreen,
    TopHeaderMenuWrapper
  },
  mixins: [globalMixin]
};
</script>
<style>
.export-button {
  width: 100%;
  background-color: rgba(36, 183, 175, 0.8);
  color: white;
  padding: 10px;
  border-radius: 3px;
  margin-bottom: 5px;
}
</style>
